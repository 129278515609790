import { WALLET_ADAPTERS } from '@web3auth/base';
import { jwtDecode } from 'jwt-decode';
import { useRouter } from 'next/router';
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useMount } from 'react-use';

import { appEnv } from '@app/config/appConfig';
import { DISCORD_CLIENT_ID, web3auth } from '@app/config/web3auth';
import {
  LOGIN_ROUTE,
  PUBLIC_ROUTES,
  CLAIM_RNFT_ROUTE
} from '@app/constants/routes';
import { useGetRfJwtToken } from '@app/context/AuthContext/hooks/useGetRfJwtToken';
import { UserInfo, JwtPayloadInfo } from '@app/types/user';

import { AuthContext } from './AuthContext';
import { getEncodedInfo } from './utils/getEncodedInfo';
import { getNearAccount } from './utils/getNearAccount';
import { MintCnftProps, mintCNftUtil } from './utils/mintCNftUtil';
import { mintRNftUtil } from './utils/mintRNftUtil';

interface AuthContextProviderProps extends PropsWithChildren {}

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const { children } = props;
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

  const { push } = useRouter();
  const getRfJwtToken = useGetRfJwtToken();

  const [init, setInit] = useState(false);
  const [provider, setProvider] = useState<any | null>(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [jwtFetched, setJwtFetched] = useState(false);

  async function generateJwtToken() {
    const { idToken } = await web3auth.authenticateUser();

    // token is being generated and added as a httpOnly cookie by backend
    const result = await getRfJwtToken(idToken);

    if (result.success && result.data?.accessToken) {
      setJwtFetched(true);
    } else {
      logout();
    }

    return result;
  }

  const loadUserInfo = async () => {
    const { data } = await generateJwtToken();

    const payload = jwtDecode<JwtPayloadInfo>(data?.accessToken || '');
    const { accountId, publicAddress } = await getEncodedInfo(provider);

    // Update state with required values
    setUserInfo({
      accountId,
      publicAddress,
      userId: Number(payload.sub),
      email: payload.email,
      subscriptions: payload.subscriptions,
      rnftMinted: payload.rnftMinted
    });
  };

  useMount(async () => {
    try {
      await web3auth.init();
      setProvider(web3auth.provider);

      const { pathname } = window.location;

      if (web3auth.connected) {
        setLoggedIn(true);

        if (LOGIN_ROUTE === pathname) {
          await generateJwtToken();
          await push(CLAIM_RNFT_ROUTE);
        }
      } else {
        if (!PUBLIC_ROUTES.includes(pathname)) {
          await push(LOGIN_ROUTE);
        }
      }

      setInit(true);
    } catch (error) {
      console.log('err');
      console.error(error);
    }
  });

  useEffect(() => {
    if (provider && web3auth.connected) {
      logout();
    }
  }, [provider, web3auth.connected]);

  const loginWithOTP = async (token: string) => {
    const web3authProvider = await web3auth.connectTo(
      WALLET_ADAPTERS.OPENLOGIN,
      {
        curve: 'ed25519',
        loginProvider: 'jwt',
        extraLoginOptions: {
          id_token: token, // token from POST /api/wallet/auth/otp/verify
          verifierIdField: 'email'
        }
      }
    );

    setProvider(web3authProvider);

    if (web3auth.connected) {
      setLoggedIn(true);
    }
  };

  const loginWithDiscord = async () => {
    const web3authProvider = await web3auth.connectTo(
      WALLET_ADAPTERS.OPENLOGIN,
      {
        curve: 'ed25519',
        loginProvider: 'discord'
      }
    );

    setProvider(web3authProvider);

    if (web3auth.connected) {
      setLoggedIn(true);
    }
  };

  const logout = useCallback(async () => {
    const { oAuthAccessToken, typeOfLogin } = await web3auth.getUserInfo();

    if (typeOfLogin === 'discord') {
      await fetch('/api/auth/discord/revoke-token', {
        method: 'POST',
        body: JSON.stringify({
          token: oAuthAccessToken,
          clientId: DISCORD_CLIENT_ID
        })
      });
    }

    await web3auth.logout();
    setProvider(null);
    setLoggedIn(false);
    setInit(false);

    setTimeout(async () => {
      await push(LOGIN_ROUTE);
      window.location.reload();
    }, 0);
  }, [push]);

  const mintRnft = useCallback(
    async (title: string, userId: number) => {
      const { accountId, privateKey } = await getEncodedInfo(provider);

      const nearAccount = await getNearAccount(
        appEnv.toLowerCase(),
        accountId,
        privateKey
      );

      return await mintRNftUtil({
        title,
        userId,
        accountId,
        nearAccount
      });
    },
    [provider]
  );

  const mintCnft = useCallback(
    async (props: MintCnftProps) => {
      const { accountId, privateKey } = await getEncodedInfo(provider);

      const nearAccount = await getNearAccount(
        appEnv.toLowerCase(),
        accountId,
        privateKey
      );

      return await mintCNftUtil({
        nearAccount,
        ...props
      });
    },
    [provider]
  );

  const contextValue = useMemo(() => {
    return {
      init,
      logout,
      mintRnft,
      mintCnft,
      loggedIn: loggedIn && jwtFetched,
      loginWithOTP,
      loginWithDiscord,
      loadUserInfo,
      userInfo
    };
  }, [init, logout, mintRnft, mintCnft, loggedIn, loadUserInfo, userInfo]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
